/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                (function(window) {

                    SlideCrossFade = {
                        container: 'images',
                        displayTime: 5000,
                        fadeTime: 1500,

                        start: function() {
                            var self = this;
                            $('.' + this.container + ' img').show();

                            setInterval(function() { self.next(); }, this.displayTime);
                        },

                        next: function() {
                            var $active = $('.' + this.container + ' .active');
                            var $next = ($active.next().length > 0) ? $active.next() : $('.' + this.container + ' img:first');

                            $next.css('z-index', 2);

                            $active.fadeOut(this.fadeTime, function() {
                                $active.css('z-index', 1).show().removeClass('active');
                                $next.css('z-index', 3).addClass('active');
                            });

                        }
                    };

                    window.SlideCrossFade = SlideCrossFade;

                })(this);

            },
            finalize: function () {

                $(window).on('load', function() {
                    SlideCrossFade.start();
                });



                AOS.init({
                    disable: 'mobile',
                    once: false,
                    startEvent: 'load',
                });

                function baseClamp(number, lower, upper) {
                    if (number === number) {
                        if (upper !== undefined) {
                            number = number <= upper ? number : upper;
                        }
                        if (lower !== undefined) {
                            number = number >= lower ? number : lower;
                        }
                    }
                    return number;
                }

                $(window).scroll(function() {
                    var scrollPos = $(this).scrollTop();
                    var pageHeight = $(document).height() - $(this).height();
                    var progress = scrollPos / pageHeight;
                    var backgroundOffset = (progress*14);
                    var backgroundOffsetTransY = (progress*1400);

                    var zoomOffsetH = baseClamp(backgroundOffsetTransY, 0, 17);
                    var zoomOffset = baseClamp(backgroundOffset, 1, 1.2);
                    var zoomOffsetIcon2 = baseClamp(backgroundOffsetTransY, 0, 20);

                    $('.logo-container').css("transform", 'translateY(' + zoomOffsetH + 'vw)');
                    $('.logo-type-container').css("transform", 'translateY(' + zoomOffsetH + 'vw)');
                    //$('.logo-container-fix').css("transform", 'translateY(' + zoomOffsetIcon2 + 'vw)');
                    //$(".parh").css("transform", 'scale(' + zoomOffsetH + ')');
                    $(".par1").css("transform", 'scale(' + zoomOffset + ')');
                });

                if (jQuery('body')){
                    var headerHeight = jQuery('.banner').outerHeight();
                }

                jQuery('a[href*="#"]')
                    .not('[href="#"]')
                    .not('[href="#0"]')
                    .click(function (event) {
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                            if (target.length) {
                                event.preventDefault();
                                jQuery(".nav-primary").removeClass('is-active');
                                $('html, body').animate({
                                    scrollTop: target.offset().top - headerHeight
                                }, 1000);
                            }
                        }
                    });

                jQuery('tr[data-pdf]').on('click', function () {
                    if (jQuery(document).width() > 768) {
                        var pdf = jQuery(this).data('pdf');
                        window.open(pdf);
                    }
                });

                jQuery('.hamburger').on('click', function (e) {
                    jQuery(this).toggleClass('is-active');
                    jQuery(".nav-primary").toggleClass('is-active');
                });

                $('.rtable tbody tr').on('mouseover', function (e) {
                    $('.house-overlay > img').removeClass('active');
                    $('.house-overlay').find('img[data-id="' + $(this).data('id') + '"]').addClass('active');
                });

                $('.rtable tbody tr').on('click', function (e) {
                    if($(this).data('status') !== "1") {
                        window.open($(this).data('pdf'), '_blank').focus();
                    }
                });


            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
